<template>
  <div id="app">
    <Header />
    <Nav />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Nav from '@/components/Nav.vue';
// import Matrix from '@/views/Matrix.vue';

export default {
  components: {
    Header,
    Nav,
    // Matrix,
  },
};
</script>

<style>
html {
  margin: 0;
}

body {
  margin: 0;
}

#app {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
}
</style>
