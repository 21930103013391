import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/draftroom",
    name: "DraftRoom",
    // route level code-splitting
    // this generates a separate chunk (draftroom.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "draftroom" */ "../views/DraftRoom.vue");
    },
  },
  {
    path: "/rules",
    name: "Rules",
    // route level code-splitting
    // this generates a separate chunk (rules.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "rules" */ "../views/Rules.vue");
    },
  },
  {
    path: "/constitution",
    name: "Constitution",
    // route level code-splitting
    // this generates a separate chunk (constitution.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "constitution" */ "../views/LeagueConstitution.vue");
    },
  },
  {
    path: "/history",
    name: "History",
    // route level code-splitting
    // this generates a separate chunk (history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "history" */ "../views/History.vue");
    },
  },
  {
    path: "/season2021",
    name: "Season2021",
    // route level code-splitting
    // this generates a separate chunk (season2021.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "season2021" */ "../components/Season2021.vue");
    },
  },
  {
    path: "/leaguevideos",
    name: "LeagueVideos",
    // route level code-splitting
    // this generates a separate chunk (leaguevideos.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "leaguevideos" */ "../views/LeagueVideos.vue");
    },
  },
  {
    path: "/leaguelosers",
    name: "LeagueLosers",
    // route level code-splitting
    // this generates a separate chunk (leaguelosers.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "leaguelosers" */ "../views/LeagueLosers.vue");
    },
  }
];

const router = new VueRouter({
  routes,
});

export default router;
