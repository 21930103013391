<template>
  <div>
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/draftroom">Drapht Room</router-link> |
      <router-link to="/rules">League Rules</router-link> |
      <router-link to="/constitution">League Constitution</router-link> |
      <router-link to="/history">League History</router-link> |
      <router-link to="/leaguevideos">League Videos</router-link> |
      <router-link to="/leaguelosers">League Losers</router-link>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#nav {
  padding: 30px;
  text-align: center;
  background-color: black;
  color: white;
  font-size: 2rem;

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 1rem;
    font-weight: 700;
  }
}

#nav a {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;

  @media (max-width: 480px) {
    font-size: 1rem;
    font-weight: 700;
  }
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
