<template>
  <div>
    <div class="background-container darken">
      <div class="welcome-text">
        Welcome To The Phantasy Phootball League Page!
      </div>
    </div>
  </div>
</template>

<script>

export default {
};
</script>

<style scoped>
.background-container {
  width: 100%;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/phantasy-phootball-league.appspot.com/o/fantasy.png?alt=media&token=429fd1c1-c164-4ba0-b97d-ac97358d25c5");
  background-repeat: repeat;
  background-position: center;
  padding-top: 230px;
  padding-bottom: 230px;

  @media (max-width: 480px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
.darken {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://firebasestorage.googleapis.com/v0/b/phantasy-phootball-league.appspot.com/o/fantasy.png?alt=media&token=429fd1c1-c164-4ba0-b97d-ac97358d25c5);
}
.welcome-text {
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 8rem;
  font-weight: 800;
  color: goldenrod;

  /* Maybe make "mobile", "tablet" and "desktop"? */
  @media (max-width: 480px) {
    font-size: 3rem;
  }
}
</style>
