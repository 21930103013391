<template>
  <div id="top-of-page">
    <div class="header-container">
      <div class="header-text">Phantasy Phootball</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.header-container {
    text-align: center;
}
.header-text {
    font-size: 6rem;

    @media (max-width: 480px) {
      font-size: 2rem;
      font-weight: 700;
    }
}
</style>
